<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" md="10">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'facturacion_servicios',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${
                loading ? '' : items.length > 0 ? `(${items.length})` : ''
              }`
            "
          />
        </v-col>
        <v-col cols="12" md="2" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                color="info"
                @click.prevent="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            Ayuda
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="warning"
                @click.prevent="getTotals"
                :disabled="items.length == 0"
              >
                <v-icon> mdi-sigma </v-icon>
              </v-btn>
            </template>
            Totales
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="9">
          <v-row dense>
            <v-col cols="12" md="4">
              <v-select
                label="Selecciona una opción..."
                v-model="filter"
                :items="filters"
                item-value="id"
                :item-text="(v) => v.name"
                dense
                :disabled="items.length > 0"
              />
            </v-col>
            <v-col v-if="filter != 1" cols="12" md="4">
              <DatePicker
                :label="'Inicial'"
                :model.sync="start"
                :disabled="items.length > 0"
              />
            </v-col>
            <v-col v-if="filter != 1" cols="12" md="4">
              <DatePicker
                :label="'Final'"
                :model.sync="end"
                :disabled="items.length > 0"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3" v-if="items.length > 0">
          <v-text-field
            v-model="items_srch"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
        <v-col cols="12">
          <v-btn
            v-if="items.length == 0"
            block
            color="info"
            x-small
            :loading="loading"
            @click.prevent="getItems"
          >
            Aplicar parámetros
            <v-icon x-small right> mdi-database-search-outline </v-icon>
          </v-btn>
          <v-btn v-else block x-small @click.prevent="items = []">
            Cambiar parámetros
            <v-icon x-small right> mdi-database-refresh-outline </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="items_hdrs"
            :search="items_srch"
            :items="items"
            :loading="loading"
            dense
          >
            <template v-slot:item.key="{ item }">
              <b>
                {{ item.key + 1 }}
              </b>
            </template>
            <template v-slot:item.amount="{ item }">
              {{ numberFormat(item.amount) }}
            </template>
            <template v-slot:item.net="{ item }">
              <v-icon small :color="item.net ? 'info' : ''">
                mdi-circle{{ item.net ? "" : "-outline" }}
              </v-icon>
            </template>
            <template v-slot:item.select="{ item }">
              <v-checkbox
                v-if="item.select != null && filter == 1"
                v-model="item.select"
                dense
              />
              <v-icon v-else small> mdi-checkbox-blank-off-outline </v-icon>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    small
                    :color="item.date_bank_check ? 'warning' : 'info'"
                    @click.prevent="getItem(item.id)"
                  >
                    <v-icon small> mdi-eye </v-icon>
                  </v-btn>
                </template>
                Detalle
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    small
                    color="pink"
                    dark
                    @click.prevent="getItemsGroup(item.group)"
                  >
                    <v-icon small> mdi-book-multiple </v-icon>
                  </v-btn>
                </template>
                Detalle de lote
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- DIALOGS -->
    <v-dialog
      v-model="payments_dialog"
      scrollable
      persistent
      max-width="1800px"
    >
      <v-card
        tile
        :disabled="payments_dialog_loading"
        :loading="payments_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> DETALLE </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="payments_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="payments_data">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'ASEGURADORAS'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Aseguradora</th>
                              <th>Monto a pagar</th>
                              <th>Cobro aseg.</th>
                              <th>Honorarios médicos hosp.</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                pay_insurance, i
                              ) in payments_data.pay_insurances"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="pay_insurance.name" />
                              <td
                                v-text="
                                  numberFormat(pay_insurance.payment_total)
                                "
                              />
                              <td>
                                {{
                                  numberFormat(
                                    pay_insurance.bill_payment_total
                                  ) +
                                  " (" +
                                  pay_insurance.quantity +
                                  ")"
                                }}
                              </td>
                              <td>
                                {{
                                  numberFormat(pay_insurance.medical_fees) +
                                  " (" +
                                  pay_insurance.quantity_medical_fees +
                                  ")"
                                }}
                              </td>
                              <td>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      fab
                                      x-small
                                      color="teal darken-3"
                                      dark
                                      @click.prevent="
                                        payInsuranceDialog(pay_insurance)
                                      "
                                    >
                                      <v-icon> mdi-group </v-icon>
                                    </v-btn>
                                  </template>
                                  <span
                                    v-text="'Concentradores | Contratantes'"
                                  />
                                </v-tooltip>
                              </td>
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payments_data.pay_insurances_payment_total
                                  )
                                "
                              />
                              <td class="font-weight-bold">
                                {{
                                  numberFormat(
                                    payments_data.pay_insurances_bill_payment_total
                                  )
                                }}
                              </td>
                              <td />
                              <td />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'ORDEN DE PAGO'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-card class="mx-auto">
                        <v-card-title>
                          <h2
                            class="text-caption"
                            v-text="
                              `${payments_data.folio} - ${
                                payments_data.provider_brand_id
                                  ? `${
                                      payments_data.provider_brand
                                        .provider_brand
                                    } | ${
                                      payments_data.provider_brand.bank.bank
                                    } | ${
                                      payments_data.format == 1
                                        ? `Cuenta ${payments_data.provider_brand.account_number}`
                                        : `CLABE ${payments_data.provider_brand.clabe}`
                                    }`
                                  : `SIN CONCENTRADOR (${
                                      payments_data.format == 1
                                        ? 'BBVA'
                                        : 'OTROS'
                                    })`
                              }`
                            "
                          />
                        </v-card-title>
                        <v-divider />
                        <v-card-text>
                          <v-row dense>
                            <v-col cols="12">
                              <v-simple-table dense>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>Folio</th>
                                      <th>Tipo</th>
                                      <th>IDP | IDM</th>
                                      <th>Proveedor | Médico</th>
                                      <th>Contratante</th>
                                      <th>Concentrador</th>
                                      <th>Aseguradora</th>
                                      <th>Doc. ID</th>
                                      <th>Recepción</th>
                                      <th>Promesa pago</th>
                                      <th>Registro</th>
                                      <th>Banco</th>
                                      <th>Beneficiario</th>
                                      <th>CLABE | CUENTA</th>
                                      <th>Monto a pagar</th>
                                      <th>Cobro aseg.</th>
                                      <th>F. pago</th>
                                      <th>Monto pagado</th>
                                      <th />
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, j) in payments_data.items"
                                      :key="j"
                                      :class="
                                        item.remove ? 'red lighten-5' : ''
                                      "
                                    >
                                      <td v-text="j + 1" />
                                      <td>
                                        {{ item.full_folio }}
                                        <v-tooltip
                                          v-if="login.permissions.rss.read"
                                          left
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-btn
                                              v-on="on"
                                              color="info"
                                              x-small
                                              icon
                                              :to="{
                                                name: 'servicios.detalle',
                                                params: {
                                                  id: item.rs_service_bill
                                                    .rs_service.rs_id,
                                                },
                                              }"
                                            >
                                              <v-icon small> mdi-eye </v-icon>
                                            </v-btn>
                                          </template>
                                          <span v-text="'Detalle'" />
                                        </v-tooltip>
                                      </td>
                                      <td v-text="item.rs_type" />
                                      <td>
                                        <span
                                          v-if="
                                            item.rs_service_bill.rs_service
                                              .doctor_id
                                          "
                                        >
                                          IDM
                                          {{
                                            item.rs_service_bill.rs_service
                                              .doctor_id
                                          }}
                                        </span>
                                        <span
                                          v-if="
                                            item.rs_service_bill.rs_service
                                              .provider_id
                                          "
                                        >
                                          IDP
                                          {{
                                            item.rs_service_bill.rs_service
                                              .provider_id
                                          }}
                                        </span>
                                      </td>
                                      <td>
                                        <span
                                          v-if="
                                            item.rs_service_bill.rs_service
                                              .doctor_id
                                          "
                                        >
                                          {{
                                            item.rs_service_bill.rs_service
                                              .doctor.name +
                                            " " +
                                            item.rs_service_bill.rs_service
                                              .doctor.first_surname +
                                            " " +
                                            item.rs_service_bill.rs_service
                                              .doctor.second_surname
                                          }}
                                        </span>
                                        <span
                                          v-if="
                                            item.rs_service_bill.rs_service
                                              .provider_id
                                          "
                                        >
                                          {{
                                            item.rs_service_bill.rs_service
                                              .provider.trade_name
                                          }}
                                        </span>
                                      </td>
                                      <td
                                        v-text="
                                          item.rs_service_bill.rs_service.rs
                                            .contractor.name
                                        "
                                      />
                                      <td
                                        v-text="
                                          item.rs_service_bill.rs_service.rs
                                            .contractor.contractor_brand
                                            ? item.rs_service_bill.rs_service.rs
                                                .contractor.contractor_brand
                                                .contractor_brand
                                            : 'PENDIENTE'
                                        "
                                      />
                                      <td
                                        v-text="
                                          item.rs_service_bill.rs_service.rs
                                            .insurance.name
                                        "
                                      />
                                      <td>
                                        {{ item.rs_service_bill.folio }}
                                        <v-tooltip
                                          v-if="
                                            login.permissions.rs_service_bills
                                              .read
                                          "
                                          left
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-btn
                                              v-on="on"
                                              color="warning"
                                              x-small
                                              icon
                                              :to="{
                                                name: 'facturacion_servicios.detalle',
                                                params: {
                                                  id: item.rs_service_bill.id,
                                                },
                                              }"
                                            >
                                              <v-icon small> mdi-eye </v-icon>
                                            </v-btn>
                                          </template>
                                          <span v-text="'Detalle'" />
                                        </v-tooltip>
                                      </td>
                                      <td
                                        v-text="
                                          item.rs_service_bill.reception_date
                                        "
                                      />
                                      <td
                                        v-text="item.rs_service_bill.pay_date"
                                      />
                                      <td v-text="item.created_at" />
                                      <td>
                                        {{
                                          item.rs_service_bill.rs_service.bank
                                            ? item.rs_service_bill.rs_service
                                                .bank.bank
                                            : "NO REGISTRADO"
                                        }}
                                      </td>
                                      <td v-text="item.beneficiary" />
                                      <td v-text="item.beneficiary_account" />
                                      <td v-text="numberFormat(item.amount)" />
                                      <td
                                        v-text="
                                          numberFormat(
                                            item.rs_service_bill
                                              .insurance_pay_amount
                                          )
                                        "
                                      />
                                      <td
                                        v-text="
                                          item.paid_date ? item.paid_date : '-'
                                        "
                                      />
                                      <td
                                        v-text="
                                          item.paid_amount
                                            ? numberFormat(item.paid_amount)
                                            : '-'
                                        "
                                      />
                                      <td>
                                        <v-icon
                                          v-if="
                                            item.verify != null &&
                                            item.verify == true
                                          "
                                          small
                                          color="success"
                                        >
                                          mdi-check
                                        </v-icon>
                                        <!-- <v-tooltip v-if="!item.paid_date" left> -->
                                        <v-tooltip v-if="false" left>
                                          <template v-slot:activator="{ on }">
                                            <v-btn
                                              class="ml-1"
                                              v-on="on"
                                              fab
                                              x-small
                                              color="warning "
                                              dark
                                            >
                                              <v-icon> mdi-pencil </v-icon>
                                            </v-btn>
                                          </template>
                                          <span v-text="'Editar'" />
                                        </v-tooltip>
                                        <v-tooltip
                                          v-if="
                                            item.verify != null &&
                                            item.verify == false &&
                                            !item.remove
                                          "
                                          left
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-btn
                                              class="ml-1"
                                              v-on="on"
                                              fab
                                              x-small
                                              color="error"
                                              dark
                                              @click.prevent="paymentRemove(j)"
                                            >
                                              <v-icon> mdi-alert </v-icon>
                                            </v-btn>
                                          </template>
                                          <span
                                            v-text="'Eliminar de esta orden'"
                                          />
                                        </v-tooltip>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="3"
                              v-if="!payments_data.date_bank_check"
                            >
                              <v-btn
                                v-if="payments_data.format == 1"
                                color="cyan darken-2"
                                class="mr-1"
                                dark
                                small
                                @click.prevent="
                                  paymentsGenerateTxt(
                                    payments_data.id,
                                    payments_data.folio,
                                    1
                                  )
                                "
                              >
                                BBVA
                              </v-btn>
                              <v-btn
                                v-if="payments_data.format == 1"
                                color="pink darken-2"
                                class="mr-1"
                                dark
                                small
                                @click.prevent="
                                  paymentsGenerateTxt(
                                    payments_data.id,
                                    payments_data.folio,
                                    3
                                  )
                                "
                              >
                                CIE
                              </v-btn>
                              <v-btn
                                v-if="payments_data.format == 2"
                                color="teal darken-3"
                                dark
                                small
                                @click.prevent="
                                  paymentsGenerateTxt(
                                    payments_data.id,
                                    payments_data.folio,
                                    2
                                  )
                                "
                              >
                                Interbancario
                              </v-btn>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="9"
                              v-if="
                                !payments_data.date_bank_check &&
                                !payments_receipt_file
                              "
                            >
                              <v-row dense>
                                <v-col cols="5">
                                  <v-file-input
                                    v-model="
                                      payments_receipt.document_receipt_0
                                    "
                                    accept=".xlsx"
                                    label="Comprobante de pago"
                                    dense
                                  />
                                </v-col>
                                <v-col cols="4">
                                  <v-select
                                    label="Lectura formato"
                                    v-model="payments_receipt.format"
                                    :items="formats"
                                    item-value="id"
                                    :item-text="(v) => v.name"
                                    dense
                                  />
                                </v-col>
                                <v-col cols="3">
                                  <v-btn
                                    block
                                    color="warning"
                                    small
                                    @click.prevent="paymentsReceiptAnalyze"
                                    :disabled="
                                      !payments_receipt.document_receipt_0 ||
                                      payments_dialog_loading
                                    "
                                    :loading="payments_dialog_loading"
                                  >
                                    Cargar comprobante
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="9"
                              class="text-right"
                              v-if="
                                !payments_data.bank_check &&
                                payments_receipt_file
                              "
                            >
                              <v-btn
                                class="mr-1"
                                small
                                @click.prevent="paymentsReceiptAnalyzeDefault"
                              >
                                Cargar otro comprobante
                              </v-btn>
                              <v-btn
                                small
                                color="success"
                                :disabled="!payments_verifieds"
                                @click.prevent="paymentsReceipt"
                              >
                                Guardar comprobante
                              </v-btn>
                            </v-col>
                            <v-col
                              cols="12"
                              class="text-right"
                              v-if="payments_data.date_bank_check"
                            >
                              <v-btn
                                color="info"
                                dark
                                small
                                :href="
                                  url_documents +
                                  '/rs_service_bill_payments/' +
                                  payments_data.url_receipt
                                "
                                target="_blank"
                              >
                                Ver comprobante de pago
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="payment_group_dialog"
      scrollable
      persistent
      max-width="2000px"
    >
      <v-card
        tile
        v-if="payment_group_dialog"
        :loading="payment_group_dialog_loading"
        :disabled="payment_group_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title>
            Lote {{ payment_group_data ? payment_group_data.group : "" }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="payment_group_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="payment_group_data">
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'ASEGURADORAS'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Ingresos</th>
                              <th>Monto pago total</th>
                              <th>Monto factura SM total</th>
                              <th>Honorarios Médicos</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                pay_insurance, i
                              ) in payment_group_data.pay_insurances"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="pay_insurance.name" />
                              <td v-text="pay_insurance.quantity" />
                              <td
                                v-text="
                                  numberFormat(pay_insurance.payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(pay_insurance.bill_payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(pay_insurance.letters_total)
                                "
                              />
                              <td>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      fab
                                      x-small
                                      color="teal darken-3"
                                      dark
                                      @click.prevent="
                                        insurancePayDetail(pay_insurance)
                                      "
                                    >
                                      <v-icon> mdi-group </v-icon>
                                    </v-btn>
                                  </template>
                                  <span
                                    v-text="'Concentradores | Contratantes'"
                                  />
                                </v-tooltip>
                              </td>
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payment_group_data.pay_insurances_payment_total
                                  )
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payment_group_data.pay_insurances_bill_payment_total
                                  )
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payment_group_data.pay_insurances_letters_total
                                  )
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'HOSPITALES'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Ingresos</th>
                              <th>Monto pago total</th>
                              <th>Monto factura SM total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                pay_provider, i
                              ) in payment_group_data.pay_providers"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="pay_provider.name" />
                              <td v-text="pay_provider.quantity" />
                              <td
                                v-text="
                                  numberFormat(pay_provider.payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(pay_provider.bill_payment_total)
                                "
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payment_group_data.pay_providers_payment_total
                                  )
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payment_group_data.pay_providers_bill_payment_total
                                  )
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              v-for="(
                rs_service_bill_payment, i
              ) in payment_group_data.rs_service_bill_payments"
              :key="i"
            >
              <v-card class="mx-auto">
                <v-card-title>
                  <h2
                    class="text-caption"
                    v-text="
                      `${
                        rs_service_bill_payment.provider_brand_id
                          ? `${rs_service_bill_payment.folio} | ${
                              rs_service_bill_payment.provider_brand
                                .provider_brand
                            } | ${
                              rs_service_bill_payment.provider_brand.bank.bank
                            } | ${
                              rs_service_bill_payment.format == 1
                                ? `Cuenta ${rs_service_bill_payment.provider_brand.account_number}`
                                : `CLABE ${rs_service_bill_payment.provider_brand.clabe}`
                            }`
                          : `SIN CONCENTRADOR (${
                              rs_service_bill_payment.format == 1
                                ? 'BBVA'
                                : 'OTROS'
                            })`
                      }`
                    "
                  />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Folio</th>
                              <th>Aseguradora</th>
                              <th>Médico / Proveedor</th>
                              <th>Contratante</th>
                              <th>Doc. ID</th>
                              <th
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id
                                "
                              >
                                Banco
                              </th>
                              <th
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id &&
                                  rs_service_bill_payment.format == 1
                                "
                              >
                                Cuenta
                              </th>
                              <th
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id &&
                                  rs_service_bill_payment.format == 2
                                "
                              >
                                CLABE
                              </th>
                              <th>F. registro</th>
                              <th>Monto factura SM</th>
                              <th>F. recepción</th>
                              <th>F. promesa de pago</th>
                              <th>Monto a pagar</th>
                              <th>F. pago</th>
                              <th>Monto pagado</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                payment, j
                              ) in rs_service_bill_payment.items"
                              :key="j"
                            >
                              <td v-text="j + 1" />
                              <td
                                v-text="
                                  payment.rs_service_bill.rs_service.rs.folio
                                "
                              />
                              <td
                                v-text="
                                  payment.rs_service_bill.rs_service.rs
                                    .insurance.name
                                "
                              />
                              <td
                                v-text="
                                  payment.rs_service_bill.rs_service
                                    .provider_name
                                "
                              />
                              <td
                                v-text="
                                  payment.rs_service_bill.rs_service.rs
                                    .contractor
                                    ? payment.rs_service_bill.rs_service.rs
                                        .contractor.name
                                    : '-'
                                "
                              />
                              <td v-text="payment.rs_service_bill_document" />
                              <td
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id
                                "
                                v-text="
                                  payment.rs_service_bill.rs_service.bank.bank
                                "
                              />
                              <td
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id &&
                                  rs_service_bill_payment.format == 1
                                "
                                v-text="
                                  payment.rs_service_bill.rs_service
                                    .account_number
                                "
                              />
                              <td
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id &&
                                  rs_service_bill_payment.format == 2
                                "
                                v-text="
                                  payment.rs_service_bill.rs_service.clabe
                                "
                              />
                              <td v-text="payment.created_at" />
                              <td
                                v-text="
                                  numberFormat(
                                    payment.rs_service_bill.insurance_pay_amount
                                  )
                                "
                              />
                              <td
                                v-text="payment.rs_service_bill.reception_date"
                              />
                              <td v-text="payment.rs_service_bill.pay_date" />
                              <td v-text="numberFormat(payment.amount)" />
                              <td
                                v-text="
                                  payment.paid_date ? payment.paid_date : '-'
                                "
                              />
                              <td
                                v-text="
                                  payment.paid_amount
                                    ? numberFormat(payment.paid_amount)
                                    : '-'
                                "
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id
                                "
                              />
                              <td
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id &&
                                  rs_service_bill_payment.format == 1
                                "
                              />
                              <td
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id &&
                                  rs_service_bill_payment.format == 2
                                "
                              />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payment_group_data
                                      .rs_service_bill_payment_amounts[i]
                                      .bill_payment_total
                                  )
                                "
                              />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payment_group_data
                                      .rs_service_bill_payment_amounts[i]
                                      .payment_total
                                  )
                                "
                              />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payment_group_data
                                      .rs_service_bill_payment_amounts[i]
                                      .paid_total
                                  )
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="insurance_payments_dialog"
      scrollable
      persistent
      max-width="1400px"
    >
      <v-card
        tile
        v-if="insurance_payments_data"
        :loading="insurance_payments_dialog_loading"
        :disabled="insurance_payments_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="insurance_payments_data.name" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insurance_payments_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2
                    class="text-caption"
                    v-text="'CONCENTRADORES | CONTRATANTES'"
                  />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Monto a pagar</th>
                              <th>Cobro aseg.</th>
                              <th>Honorarios médicos hosp.</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                contractor_brand, i
                              ) in insurance_payments_data.contractor_brands"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="contractor_brand.name" />
                              <td
                                v-text="
                                  numberFormat(contractor_brand.payment_total)
                                "
                              />
                              <td>
                                {{
                                  numberFormat(
                                    contractor_brand.bill_payment_total
                                  ) +
                                  " (" +
                                  contractor_brand.quantity +
                                  ")"
                                }}
                              </td>
                              <td>
                                {{
                                  numberFormat(contractor_brand.medical_fees) +
                                  " (" +
                                  contractor_brand.quantity_medical_fees +
                                  ")"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    insurance_payments_data.payment_total
                                  )
                                "
                              />
                              <td class="font-weight-bold">
                                {{
                                  numberFormat(
                                    insurance_payments_data.bill_payment_total
                                  ) +
                                  " (" +
                                  insurance_payments_data.quantity +
                                  ")"
                                }}
                              </td>
                              <td class="font-weight-bold">
                                {{
                                  numberFormat(
                                    insurance_payments_data.medical_fees
                                  ) +
                                  " (" +
                                  insurance_payments_data.quantity_medical_fees +
                                  ")"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="pay_insurance_dlg"
      scrollable
      persistent
      max-width="1400px"
    >
      <v-card
        tile
        v-if="pay_insurance"
        :loading="pay_insurance_dlg_ldg"
        :disabled="pay_insurance_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="pay_insurance.name" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="pay_insurance_dlg = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2
                    class="text-caption"
                    v-text="'CONCENTRADORES | CONTRATANTES'"
                  />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Ingresos</th>
                              <th>Monto pago total</th>
                              <th>Monto factura SM total</th>
                              <th>Honorarios Médicos</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                contractor_brand, i
                              ) in pay_insurance.contractor_brands"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="contractor_brand.name" />
                              <td v-text="contractor_brand.quantity" />
                              <td
                                v-text="
                                  numberFormat(contractor_brand.payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(
                                    contractor_brand.bill_payment_total
                                  )
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(contractor_brand.letters_total)
                                "
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(pay_insurance.payment_total)
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(pay_insurance.bill_payment_total)
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(pay_insurance.letters_total)
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="totals_dlg" scrollable persistent max-width="1800px">
      <v-card tile :loading="totals_ldg" :disabled="totals_ldg">
        <v-toolbar dark dense>
          <v-toolbar-title> TOTALES </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="totals_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="totals" class="pt-3">
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption">CONCENTRADORES</h2>
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-data-table
                      :headers="contractor_brands_hdrs"
                      :items="totals.contractor_brands"
                      dense
                    >
                      <template v-slot:item.index="{ index }">
                        <b>{{ index + 1 }}</b>
                      </template>
                      <template v-slot:item.consolidated="{ item }">
                        <v-icon small :color="item.consolidated ? 'info' : ''">
                          mdi-circle{{ item.consolidated ? "" : "-outline" }}
                        </v-icon>
                      </template>
                      <template v-slot:item.amount="{ item }">
                        {{ numberFormat(item.amount) }}
                      </template>
                      <template v-slot:item.insurance_pay_amount="{ item }">
                        {{ numberFormat(item.insurance_pay_amount) }}
                        | ({{ item.insurance_pay_quantity }})
                      </template>
                      <template v-slot:item.medical_fees_amount="{ item }">
                        <span v-if="item.medical_fees_quantity > 0">
                          {{ numberFormat(item.medical_fees_amount) }} | ({{
                            item.medical_fees_quantity
                          }})
                        </span>
                        <span v-else>-</span>
                      </template>
                      <template v-slot:item.withdraw_amount="{ item }">
                        {{ numberFormat(item.withdraw_amount) }}
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col cols="12" v-for="(item, i) in totals.items" :key="i">
            <v-card class="mx-auto">
              <v-card-title>
                <v-btn icon small @click.prevent="item.show = !item.show">
                  <v-icon small>
                    mdi-chevron-{{ item.show ? "down" : "up" }}
                  </v-icon>
                </v-btn>
                <span class="text-caption">
                  {{ item.folio }} ({{
                    item.rs_service_bill_payment_items.length
                  }}) | {{ item.provider_brand.name }}
                </span>
              </v-card-title>
              <v-divider />
              <v-card-text v-if="item.show">
                <v-row dense>
                  <v-col cols="12">
                    <v-data-table
                      :headers="rs_service_bill_payment_items_hdrs"
                      :items="item.rs_service_bill_payment_items"
                      dense
                    >
                      <template v-slot:item.index="{ index }">
                        <b>{{ index + 1 }}</b>
                      </template>
                      <template v-slot:item.rs_service.folio="{ item }">
                        {{ item.rs_service.folio }}
                        <v-tooltip v-if="login.permissions.rss.read" left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              color="info"
                              x-small
                              icon
                              :to="{
                                name: 'servicios.detalle',
                                params: {
                                  id: item.rs_service.rs_id,
                                },
                              }"
                            >
                              <v-icon small> mdi-eye </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Detalle'" />
                        </v-tooltip>
                      </template>
                      <template v-slot:item.rs_service_bill.folio="{ item }">
                        {{ item.rs_service_bill.folio }}
                        <v-tooltip
                          v-if="login.permissions.rs_service_bills.read"
                          left
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              color="warning"
                              x-small
                              icon
                              :to="{
                                name: 'facturacion_servicios.detalle',
                                params: { id: item.rs_service_bill_id },
                              }"
                            >
                              <v-icon small> mdi-eye </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Detalle'" />
                        </v-tooltip>
                      </template>
                      <template v-slot:item.amount="{ item }">
                        {{ numberFormat(item.amount) }}
                      </template>
                      <template
                        v-slot:item.rs_service_bill.insurance_pay_amount="{
                          item,
                        }"
                      >
                        {{
                          numberFormat(
                            item.rs_service_bill.insurance_pay_amount
                          )
                        }}
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgAlert,
  msgConfirm,
  rules,
  toFormData,
  URL_DOCUMENTS,
  dateTimeNow,
} from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";
import DatePicker from "../../components/DatePicker.vue";
import ViewData from "../../components/ViewData.vue";

export default {
  components: {
    FaqDlg,
    DatePicker,
    ViewData,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      loading: false,
      items: [],
      items_srch: "",
      items_hdrs: [
        {
          text: "#",
          value: "key",
          sortable: false,
          filterable: false,
          width: "60px",
        },
        {
          text: "Folio",
          value: "folio",
        },
        {
          text: "Fecha",
          value: "created_at",
        },
        {
          text: "Concentrador",
          value: "provider_brand_name",
        },
        {
          text: "Lote",
          value: "group",
        },
        {
          text: "Regs.",
          value: "quantity",
        },
        {
          text: "Monto",
          value: "amount",
        },
        {
          text: "NET",
          value: "net",
        },
        {
          text: "Selec.",
          filterable: false,
          value: "select",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          filterable: false,
          width: "100px",
        },
      ],
      filter: 1,
      filters: [
        { id: 1, name: "PENDIENTES" },
        { id: 2, name: "CARGADOS" },
        { id: 0, name: "TODOS" },
      ],
      filter_insurance: [],
      filter_insurances: [],
      start: null,
      end: null,
      rules: rules(),
      payments_data: null,
      payments_dialog: null,
      payments_dialog_loading: null,
      insurance_payments_data: null,
      insurance_payments_dialog: null,
      insurance_payments_dialog_loading: null,
      payments_receipt: {
        rs_service_bill_payment_items: null,
        document_receipt_0: null,
        format: null,
      },
      formats: [
        {
          id: 1,
          name: "BBVA",
        },
        {
          id: 3,
          name: "BBVA CIE",
        },
        {
          id: 2,
          name: "INTERBANCARIO",
        },
      ],
      payments_receipt_file: false,
      payments_verifieds: null,
      url_documents: URL_DOCUMENTS,
      payments_remove: 0,
      pay_verifieds_total: 0,
      payment_group_data: null,
      payment_group_dialog: false,
      payment_group_dialog_loading: false,
      pay_insurance: null,
      pay_insurance_dlg_ldg: false,
      pay_insurance_dlg: false,
      totals: null,
      totals_ldg: false,
      totals_dlg: false,
      rs_service_bill_payment_items_hdrs: [
        {
          text: "#",
          value: "index",
          sortable: false,
          filterable: false,
          width: "60px",
        },
        {
          text: "Folio",
          value: "rs_service.folio",
        },
        {
          text: "Tipo",
          value: "rs.rs_type.name",
        },
        {
          text: "IDP | IDM",
          value: "rs_service.idp_idm",
        },
        {
          text: "Proveedor | Médico",
          value: "rs_service.provider_name",
        },
        {
          text: "Contratante",
          value: "rs.contractor",
        },
        {
          text: "Concentrador",
          value: "contractor_brand.name",
        },
        {
          text: "Aseguradora",
          value: "rs.insurance.name",
        },
        {
          text: "Doc. ID",
          value: "rs_service_bill.folio",
        },
        {
          text: "Recepción",
          value: "rs_service_bill.reception_date",
        },
        {
          text: "Promesa pago",
          value: "rs_service_bill.pay_date",
        },
        {
          text: "Registro",
          value: "created_at",
        },
        {
          text: "Banco",
          value: "rs_service.bank.name",
        },
        {
          text: "Beneficiario",
          value: "beneficiary",
        },
        {
          text: "CLABE | Cuenta",
          value: "beneficiary_account",
        },
        {
          text: "Monto a pagar",
          value: "amount",
        },
        {
          text: "Cobro aseg.",
          value: "rs_service_bill.insurance_pay_amount",
        },
      ],
      contractor_brands_hdrs: [
        {
          text: "#",
          value: "index",
          sortable: false,
          filterable: false,
          width: "60px",
        },
        {
          text: "Concentrador",
          value: "name",
        },
        {
          text: "Consolidado",
          value: "consolidated",
        },
        {
          text: "Monto a pagar",
          value: "amount",
        },
        {
          text: "Cobro aseg.",
          value: "insurance_pay_amount",
        },
        {
          text: "Honorarios médicos hosp.",
          value: "medical_fees_amount",
        },
        {
          text: "Monto fondeo",
          value: "withdraw_amount",
        },
      ],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getItems() {
      this.loading = true;
      this.items = [];

      Axios.get(
        URL_API +
          "/rss/services/bills/payments" +
          "?filter=" +
          this.filter +
          "&start=" +
          this.start +
          "&end=" +
          this.end,
        headersToken(this.login.token)
      ).then((resp) => {
        this.items = resp.data.data;
        this.loading = false;

        if (this.items.length == 0) {
          this.$swal.fire(
            msgAlert("warning", "Sin resultados al aplicar filtros")
          );
        }
      });
    },
    getItem(id) {
      this.payments_data = null;
      this.payments_dialog_loading = true;
      this.payments_dialog = true;
      this.payments_receipt.document_receipt_0 = null;
      this.payments_receipt.format = null;
      this.payments_receipt_file = false;
      this.payments_verified = null;

      Axios.get(
        `${URL_API}/rss/services/bills/payments/${id}`,
        headersToken(this.login.token)
      ).then((resp) => {
        this.payments_data = resp.data.data;
        this.payments_receipt.format = this.payments_data.format;

        this.payments_dialog_loading = false;
      });
    },
    getItemsGroup(group) {
      this.payment_group_dialog = true;
      this.payment_group_data = null;
      this.payment_group_dialog_loading = true;

      Axios.get(
        `${URL_API}/rss/services/bills/payments/group/${group}`,
        headersToken(this.login.token)
      ).then((response) => {
        this.payment_group_data = response.data.data;
        this.payment_group_data.group = group;
        this.payment_group_dialog_loading = false;
      });
    },
    paymentsGenerateTxt(id, folio, format) {
      this.payments_dialog_loading = true;

      Axios.get(
        URL_API + `/rss/services/bills/payments/${id}/file/${format}`,
        headersToken(this.login.token)
      ).then((resp) => {
        this.$swal.fire(
          msgAlert(resp.data.success ? "success" : "error", resp.data.message)
        );

        if (resp.data.success) {
          this.payments_dialog_loading = false;

          const linkSource = `data:application/txt;base64,${resp.data.data}`;
          const downloadLink = document.createElement("a");
          const fileName = `SMS_${folio}_${resp.data.now}_${resp.data.format}.txt`;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          console.log(resp.data.message);
        }
      });
    },
    payInsuranceDialog(data) {
      this.insurance_payments_data = null;
      this.insurance_payments_dialog_loading = true;
      this.insurance_payments_dialog = true;
      this.insurance_payments_data = data;
      this.insurance_payments_dialog_loading = false;
    },
    paymentsReceiptAnalyze() {
      this.payments_dialog_loading = true;
      this.payments_receipt_file = false;
      this.payments_remove = 0;
      this.payments_verifieds = null;
      this.pay_verifieds_total = 0;
      this.payments_receipt.rs_service_bill_payment_items =
        this.payments_data.items;
      this.payments_receipt.rs_service_bill_payment_id = this.payments_data.id;

      Axios.post(
        `${URL_API}/rss/services/bills/payments/receipt/analyze`,
        toFormData(this.payments_receipt),
        headersToken(this.login.token)
      ).then((resp) => {
        this.$swal.fire(
          msgAlert(resp.data.success ? "success" : "error", resp.data.message)
        );

        if (resp.data.success) {
          this.payments_data.items = resp.data.data;
          this.payments_receipt.rs_service_bill_payment_items = resp.data.data;
          this.payments_verifieds = resp.data.pay_verifieds;
          this.pay_verifieds_total = resp.data.pay_verifieds_total;
          this.payments_receipt_file = true;
        } else {
          console.log(resp.data.message);
        }

        this.payments_dialog_loading = false;
      });
    },
    paymentsReceiptAnalyzeDefault() {
      this.payments_receipt_file = false;
      this.payments_verifieds = null;

      for (let item of this.payments_data.items) {
        item.paid_date = null;
        item.paid_amount = null;
        item.verify = null;
      }
    },
    paymentsReceipt() {
      this.$swal
        .fire(
          msgConfirm(`¿Confirma guardar comprobante para la orden de pago?`)
        )
        .then((resp) => {
          if (resp.isConfirmed) {
            this.payments_dialog_loading = true;

            Axios.post(
              `${URL_API}/rss/services/bills/payments/receipt`,
              toFormData(this.payments_receipt),
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.payments_dialog = false;
                this.payments_dialog_loading = false;
                this.getItems();
              } else {
                console.log(resp.data.message);
              }
            });
          }
        });
    },
    paymentRemove(index) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar pago de orden?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            this.payments_data.items[index].remove = true;
            this.payments_remove++;

            const total_items = this.payments_remove + this.pay_verifieds_total;

            if (total_items == this.payments_data.items.length) {
              this.payments_verifieds = true;
            }
          }
        });
    },
    insurancePayDetail(pay_insurance) {
      this.pay_insurance = null;
      this.pay_insurance_dlg_ldg = true;
      this.pay_insurance_dlg = true;
      this.pay_insurance = pay_insurance;
      this.pay_insurance_dlg_ldg = false;
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    getTotals() {
      let items_ids = [];

      for (const item of this.items) {
        if (item.select) {
          items_ids.push(item.id);
        }
      }

      if (items_ids.length > 0) {
        this.totals = null;
        this.totals_ldg = true;
        this.totals_dlg = true;

        Axios.post(
          URL_API + "/rss/services/bills/payments/totals",
          { items_ids: items_ids },
          headersToken(this.login.token)
        ).then((rsp) => {
          this.totals = rsp.data.data;
          this.totals_ldg = false;
        });
      } else {
        this.$swal.fire(msgAlert("warning", "Sin registros seleccionados"));
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.start = dateTimeNow().substring(0, 8) + "01";
    this.end = dateTimeNow().substring(0, 10);
  },
};
</script>
